<template>
<div class="app-container">
  <el-col :sm="24" :md="16" >
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>Agregar Comunicado</span>
      </div>
      <div class="text item">
        <ValidationObserver ref="form">
          <el-form label-position="top" label-width="100px" :model="form">
              <el-form-item label="Titulo" size="mini">
                <ValidationProvider name="titulo" rules="required" v-slot="{ errors }">
                  <el-input v-model="form.title"></el-input>
                  <span> </span>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Descripción corta" size="mini">
                <ValidationProvider name="descripción" rules="required" v-slot="{ errors }">
                  <el-input v-model="form.description"></el-input>
                  <span> </span>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Imagen">
                <div v-if="form.image">
                  <el-image :src="$urlGlobalApi +form.image">
                    <div slot="placeholder" class="image-slot">
                      Loading<span class="dot">...</span>
                    </div>
                  </el-image>
                </div>
                <el-upload
                  drag
                  name="image"
                  :http-request="postImage"
                  :on-success="handleAvatarSuccess"
                  multiple>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">Suelta tu imagen aquí o <em>haz clic para cargar</em></div>
                  <div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb (800 x 450)</div>
                </el-upload>
              </el-form-item>
              <el-form-item label="Link Video" size="mini">
                <!--<ValidationProvider name="idVimeo" rules="required" v-slot="{ errors }">
                  <span> </span>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>-->
                <el-input v-model="form.idVimeo" placeholder="https"></el-input>
                <span><strong>https</strong>://vimeo.com/578105796</span><br>
              </el-form-item>
              <el-form-item label="Contenido">
                <tinyeditor v-model="form.content"
                  api-key='epme9s1ejp9yit6h3lokaj05yohsvjm61r9y7kdrvm0wm9ex'
                    :init="{
                      height: 500,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help'
                    }"
                  />
              </el-form-item>

              <el-form-item label="Tiempo de lectura">
                <ValidationProvider name="tiempo de lectura" rules="required" v-slot="{ errors }">
                  <el-input size="mini" v-model="form.timeRead"></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
             <el-form-item label="Palabras Clave">
                <ValidationProvider name="palabras Clave" rules="required" v-slot="{ errors }">
                  <el-select
                  size="mini"
                  v-model="form.tags"
                  multiple
                  filterable
                  allow-create
                  placeholder="Comunicación">
                    <el-option
                      v-for="itemT in optionsTags"
                      :key="itemT.value"
                      :label="itemT.label"
                      :value="itemT.value">
                    </el-option>
                  </el-select>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Fecha de publicación">
                <ValidationProvider name="fecha de publicación" rules="required" v-slot="{ errors }">
                  <el-date-picker
                    size="mini"
                    v-model="form.datePublication"
                    type="date"
                    format="dd-MM-yyyy"
                    placeholder="Fecha">
                  </el-date-picker>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Estatus">
                <el-switch
                  v-model="form.status"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
                </el-switch>
              </el-form-item>
              <el-form-item>
                <!--<el-form-item label="Estatus">
                <ValidationProvider name="Status" rules="required" v-slot="{ errors }">
                  <el-select
                  size="mini"
                  v-model="form.status"
                  filterable
                  allow-create
                  placeholder="Seleccionar">
                    <el-option
                      v-for="item in optionStatus"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>-->
              <el-button
                @click.stop.prevent="onStore"
                type="primary"
                icon="el-icon-upload2">
                Guardar</el-button>
              </el-form-item>
          </el-form>
        </ValidationObserver>
      </div>
    </el-card>
  </el-col>
</div>
</template>
<script>
import { storeReleases } from '@/api/releases'
import { uploadImageFile } from '@/api/image'

export default {
  data () {
    return {
      limitTitle: 65,
      limitDescription: 155,
      form: {
        title: '',
        description: '',
        thumb: '',
        image: '',
        video: '',
        content: '',
        timeRead: '',
        tags: [],
        datePublication: '',
        status: true
      },
      optionStatus: [{
        value: true,
        label: 'Si'
      }, {
        value: false,
        label: 'No'
      }],
      loading: false,
      loadingDelete: false,
      isVisibleCollection: false,
      formEditCollection: {},
      optionsTags: [{
        value: 'Pollotón 2020',
        label: 'Pollotón 2020'
      }, {
        value: 'Toluca',
        label: 'Toluca'
      }]
    }
  },
  created () {
    this.form.admin_id = this.$store.getters.id
  },
  methods: {
    async postImage (param) {
      this.loading = true
      const formData = new FormData()
      formData.append('image', param.file)
      formData.append('width', '800')
      formData.append('height', '450')
      formData.append('title', 'imagen-comunicado')
      formData.append('alt', 'imagen-800x450')
      uploadImageFile(formData).then((response) => {
        console.log(response)
        param.onSuccess(response.data)
      })
        .catch(response => {
          console.log(response)
          param.onError()
        })
        .finally(() => (this.loading = false))
    },
    async onStore () {
      await storeReleases(this.form)
        .then(() => {
          // let res = response.data
          this.$message({
            showClose: true,
            message: 'Se ha creado un nuevo comunicado',
            type: 'success'
          })
          this.$router.push({ name: 'releases' })
        })
        .catch(this.responseCatch)
    },
    async onUpdate () {
      await storeReleases(this.form)
        .then(() => {
          // let res = response.data
          this.$message({
            showClose: true,
            message: 'Sea publicado un nuevo blog!',
            type: 'success'
          })
          this.$router.push({ name: 'blog' })
        })
        .catch(this.responseCatch)
    },
    clearFilter () {
      this.$refs.filterTable.clearFilter()
    },
    handleAvatarSuccess (res, file) {
      this.form.image = res.path
      this.form.thumb = res.pathThumb
    }
  },
  computed: {
    limitTitleCount () {
      return this.limitTitle - this.form.titleSeo.length
    },
    limitDescriptionCount () {
      return this.limitDescription - this.form.descriptionSeo.length
    }
  }
}
</script>
